import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Projects.scss';
// import { projects } from './projects';
import Axios from 'axios';
import { Error } from '../../components/Error/Error.jsx';
import { Loader } from '../../components/Loader/Loader.jsx';
import { API_URL } from '../../config';

export const Projects = () => {
	const [projects, setProjects] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [sortedProjects, setSortedProjects] = useState([]);
	const [projectTitle, setProjectTitle] = useState(null);

	/* < Obtener los proyectos > */

	const getProjects = async () => {
		setLoading(true);
		const url = `${API_URL}/projects`;

		try {
			const { data: response } = await Axios.get(url);
			setProjects(response.data);
			//console.log(response.data);
		} catch (error) {
			console.log(error);
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getProjects();
	}, [setProjects]); // eslint-disable-line

	//Ordenar los proyectos por fecha de creación
	const sortProjects = () => {
		const sorted = projects.sort((a, b) => {
			return new Date(b.createdAt) - new Date(a.createdAt);
		});
		setSortedProjects(sorted);
	};

	useEffect(() => {
		sortProjects();
	}, [projects]); // eslint-disable-line

	/* < Funciones para mostrar el título del proyecto al pasar el mouse por encima de la imagen > */
	const handleHover = (e) => {
		const title = e.target.getAttribute('project_title');
		setProjectTitle(title);
	};

	const handleMouseOut = () => {
		setProjectTitle('');
	};

	return (
		<div className='Projects'>
			{loading && <Loader />}
			{error && <Error message={error} />}
			<div className='projects-img-container'>
				{sortedProjects.map((project) => (
					<Link
						to={`/${project._id}`}
						key={project._id}
						project_title={project.title}
					>
						<img
							src={project.cover?.secure_url}
							alt={project.name}
							id={project._id}
							project_title={project.title}
							onMouseOver={(e) => handleHover(e)}
							onMouseOut={handleMouseOut}
						/>
						<p>{project.title}</p>
					</Link>
				))}
			</div>
			{!projectTitle ? (
				<div className='projects-text-container'>
					<p></p>
				</div>
			) : (
				<div className='projects-text-container'>
					<p>{projectTitle}</p>
				</div>
			)}
		</div>
	);
};
