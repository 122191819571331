import React from 'react';
import './Dashboard.scss';
import { Logout } from '../../components/Logout/Logout';
import { Link } from 'react-router-dom';
import { FiList } from 'react-icons/fi';
import { GoPlus } from 'react-icons/go';

export const Dashboard = () => {
	return (
		<div className='Dashboard'>
			<div className='container'>
				<Logout />

				<h1>Dashboard</h1>

				<Link to='/create-project'>
					<div>
						<GoPlus />
						<h4>Crear un proyecto</h4>
					</div>
				</Link>

				<Link to='/list-projects'>
					<div>
						<FiList />
						<h4>Listado de proyectos</h4>
					</div>
				</Link>

				<Link to='/create-category-about'>
					<div>
						<GoPlus />
						<h4>Crear categoría de About</h4>
					</div>
				</Link>

				<Link to='/list-categories-about'>
					<div>
						<FiList />
						<h4>Listado de categorías de About</h4>
					</div>
				</Link>

				<Link to='/create-studio'>
					<div>
						<GoPlus />
						<h4>Crear estudio</h4>
					</div>
				</Link>
			</div>
		</div>
	);
};
