export const material = [
	{
		category: 'recording',
		title: 'Recording',
		material: (
			<p>
				Dynaudio BM15A <br />
				Mytek 8X192 ADDA
				<br />
				Chandler Limited TG2
				<br />
				Russian Preamp
				<br />
				Avalon U5
				<br />
				Radial J33 phono preamp DI
				<br />
				Overstayer Modular Channel 8755dm
				<br />
				Thermionic Culture Ltd The Culture Vulture
				<br />
				Tube-Tech PE1B
				<br />
				Roger Mayer 456 HD
				<br />
				Little Labs PCP Instrument distro 3.1
				<br />
				Olympus LS-100
				<br />
				Schertler Dyn G<br />
				Shure sm57
				<br />
				Oktava ML-19
				<br />
				Reslo RB Beep (Xaudia mod)
				<br />
				Grundig gdm 15
				<br />
				Geofón
			</p>
		),
		images: [
			{
				secure_url: 'studio_recording_1.jpg',
				public_id: 'public_id',
			},
			{
				secure_url: 'studio_recording_2.jpg',
				public_id: 'public_id',
			},
			{
				secure_url: 'studio_recording_3.jpg',
				public_id: 'public_id',
			},
		],
	},
	{
		category: 'synths',
		title: 'Synths',
		material: (
			<p>
				Moog Voyager <br />
				Moog Prodigy
				<br />
				Moog Little Phatty
				<br />
				Korg MS-20
				<br />
				Modular synthesizer
				<br />
				Dave Smith Prophet 08
				<br />
				Korg Polysix
				<br />
				Teenage Engineering OP-1
				<br />
				Siel Orchestra II
				<br />
				Logan String Melody II
				<br />
				Hohner pianet M<br />
				E-mu Proteus/1
				<br />
				Casio SK-1
				<br />
				Casio PT-1
				<br />
				Yamaha VSS-30
				<br />
				MXR 185
				<br />
				Yamaha PS-30
				<br />
				Yamaha P-80
				<br />
				Kawai K1m
				<br />
				Baldwin Discovery
				<br />
				Bontempi electric chord organ
				<br />
				Arturia Beatstep pro
				<br />
				Monogram audio console
				<br />
				M-Audio Axiom 61
			</p>
		),
		images: [
			{
				secure_url: 'studio_synths_1.jpg',
				public_id: 'public_id',
			},
			{
				secure_url: 'studio_synths_2.jpg',
				public_id: 'public_id',
			},
			{
				secure_url: 'studio_synths_3.jpg',
				public_id: 'public_id',
			},
		],
	},
	{
		category: 'samplers',
		title: 'Samplers',
		material: (
			<p>
				Akai S950
				<br />
				Akai S612
				<br />
				Akai MPC 2500
				<br />
				Teenage Engineering OP-1
			</p>
		),
		images: [
			{
				secure_url: 'studio_samplers_1.jpg',
				public_id: 'public_id',
			},
			{
				secure_url: 'studio_samplers_2.jpg',
				public_id: 'public_id',
			},
			{
				secure_url: 'studio_samplers_3.jpg',
				public_id: 'public_id',
			},
		],
	},
	{
		category: 'fxs',
		title: 'FXs',
		material: (
			<p>
				Korg DTR-1
				<br />
				Sherman Filterbank 2<br />
				SansAmp Tech 21 NYC
				<br />
				Roland Chorus Echo RE-501
				<br />
				Yamaha SPX90
				<br />
				Digitech DSP 128
				<br />
				Digitech Vocalist VHM5
				<br />
				Furman Sound RV-1
				<br />
				Boss DE-200
				<br />
				Boss RV-1000
				<br />
				Boss RCE-10
				<br />
				Boss RBF-10
				<br />
				Alesis Quadraverb
				<br />
				Roland DEP-5
				<br />
				Fairfield Circuitry Shallow water
				<br />
				Chase Bliss Warped Vinyl HiFi
				<br />
				Chase Bliss blooper
				<br />
				DigiTech Whammy-Wha XP 100
				<br />
				DigiTech JamMan st
				<br />
				Fulltone 70
				<br />
				Moogerfooger Ring Modulator
				<br />
				Minifooger MF Delay
				<br />
				Minifooger MF Drive
				<br />
				Industrialectric RM-1N
				<br />
				Strymon BlueSky
				<br />
				Electro Harmonix Holygrail
				<br />
				Electro Harmonix Memoryman
				<br />
				Electro Harmonix Electric Mistress
				<br />
				Electro Harmonix Pitch Fork
				<br />
				Electro Harmonix Cathedral
				<br />
				Electro Harmonix Small Stone
				<br />
				Hughes and Kettner rotosphere
				<br />
				Fuzz Face clone
				<br />
				Fuzz Factory clone
				<br />
				Hot Cake clone
			</p>
		),
		images: [
			{
				secure_url: 'studio_fxs_1.jpg',
				public_id: 'public_id',
			},
			{
				secure_url: 'studio_fxs_2.jpg',
				public_id: 'public_id',
			},
			{
				secure_url: 'studio_fxs_3.jpg',
				public_id: 'public_id',
			},
		],
	},
	{
		category: 'others',
		title: 'Others',
		material: (
			<p>
				Nagra 4.2 <br />
				Tascam 414 mkII
				<br />
				Korg CR4
				<br />
				Oscar Schmidt Autoharp <br />
				Vintage Autoharp
				<br />
				Danelectro U2 guitar
				<br />
				Fender '65 Twin Reverb Black Face
				<br />
				Elektra Nordmende Radio Amp
				<br />
				FirstAct Portable Amplifier
				<br />
				Technics SL-1200MK2
			</p>
		),
		images: [
			{
				secure_url: 'studio_others_1.jpg',
				public_id: 'public_id',
			},
			{
				secure_url: 'studio_others_2.jpg',
				public_id: 'public_id',
			},
			{
				secure_url: 'studio_others_3.jpg',
				public_id: 'public_id',
			},
		],
	},
];
