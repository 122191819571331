import React, { useState, useEffect } from 'react';
import { ReactSortable } from 'react-sortablejs';
import './ProjectList.scss';
import { Error } from '../../components/Error/Error.jsx';
import { Loader } from '../../components/Loader/Loader.jsx';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { API_URL } from '../../config.js';
import { BackButton } from '../../components/BackButton/BackButton.jsx';

export const ProjectList = () => {
	const [projects, setProjects] = useState([]);
	const [sortedProjects, setSortedProjects] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [message, setMessage] = useState(null);

	/* < Obtener los proyectos > */

	const getProjects = async () => {
		setLoading(true);
		const url = `${API_URL}/projects`;

		try {
			const { data: response } = await Axios.get(url);
			setProjects(response.data);
			// console.log(response.data);
		} catch (error) {
			console.log(error);
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getProjects();
	}, [setProjects]); // eslint-disable-line

	/* < Ordenar los proyectos por fecha de creación > */
	// const sortProjects = () => {
	// 	const sorted = projects?.sort((a, b) => {
	// 		return new Date(b.createdAt) - new Date(a.createdAt);
	// 	});
	// 	setSortedProjects(sorted);
	// };

	// Recuperar los ids del localStorage y ordenar los proyectos según esos ids
	const sortProjects = () => {
		const sortedNames = JSON.parse(localStorage.getItem('sortedNames'));
		// console.log(
		// 	'Ids recuperados del localStorage tras recarga: ' + sortedIds
		// );
		if (sortedNames) {
			const sortedProjects = projects.sort((a, b) => {
				return (
					sortedNames.indexOf(a.name) - sortedNames.indexOf(b.name)
				);
			});
			setSortedProjects(sortedProjects);
			//mostrar los ids ordenados de los proyectos en la consola:
			sortedProjects.forEach((project, index) => {
				console.log(`ID: ${project.name}, Posición: ${index + 1}`);
			});
		} else {
			setSortedProjects(projects);
		}
	};

	useEffect(() => {
		sortProjects();
	}, [projects]); // eslint-disable-line

	/* < Eliminar proyecto > */
	const deleteProject = async (id) => {
		const url = `${API_URL}/projects/${id}`;
		//mostrar mensaje de confirmación
		const confirm = window.confirm(
			'¿Estás seguro de que quieres eliminar el proyecto?'
		);
		if (!confirm) return;
		try {
			const { data: response } = await Axios.delete(url);
			//console.log(response);
			setMessage(response.message);
			getProjects();
		} catch (error) {
			console.log(error);
			setError(error.message);
		}
	};

	// Drag and Drop Handler
	const onDragDropEnds = (oldIndex, newIndex) => {
		// console.log('Drag and drop other tasks');
		/*
		console.log('Viejo índice: ' + oldIndex, ', nuevo índice: ' + newIndex);
		sortedProjects.forEach((project, index) => {
			console.log(`ID: ${project._id}, Posición: ${index + 1}`);
		});
		*/

		//Guardar los Nombres ordenados de los proyectos en el localStorage:
		//! Aquí está el error. Lo primero que tengo que hacer es hacer un setSortedProjects con los proyectos ordenados, teniendo en cuenta el cambio de índices, y luego guardarlos en el localStorage.
		//Cojo el array de proyectos inicial:
		const projectsCopy = [...projects];
		//Hago el cambio de proyectos en el array con los nuevos índices:
		const [removed] = projectsCopy.splice(oldIndex, 1);
		projectsCopy.splice(newIndex, 0, removed);
		//Console.log de los proyectos ordenados:
		projectsCopy.forEach((project, index) => {
			console.log(`Nombre: ${project.name}, Posición: ${index + 1}`);
		});
		//Guardo los proyectos ordenados en el estado:
		setSortedProjects(projectsCopy);
		//Guardo los nombres de los proyectos ordenados en el localStorage:

		const sortedNames = sortedProjects.map((project) => project.name);
		localStorage.setItem('sortedNames', JSON.stringify(sortedNames));
		console.log('Names ordenados: ' + sortedNames);

		//Recuperar del localStorage los Names ordenados de los proyectos:
		const achievedNames = JSON.parse(localStorage.getItem('sortedNames'));
		console.log('Names recuperados: ' + achievedNames);
	};

	return (
		<div className='ProjectList'>
			{loading && <Loader />}

			<div className='container'>
				<BackButton to='/dashboard' text='Dashboard' />
				<h3>LISTA DE PROYECTOS</h3>

				{message && <p style={{ color: 'green' }}>{message}</p>}
				{error && <Error message={error} />}

				{sortedProjects?.length === 0 && (
					<h4>No hay proyectos todavía</h4>
				)}
				<ReactSortable
					list={sortedProjects}
					setList={(newlist) => setSortedProjects(newlist)}
					ghostClass='dropArea'
					handle='.dragHandle'
					filter='.ignoreDrag'
					preventOnFilter={true}
					// className='grid-container'
					onEnd={({ oldIndex, newIndex }) =>
						onDragDropEnds(oldIndex, newIndex)
					}
				>
					{sortedProjects?.map((project) => (
						// La clase dragHandle es necesaria para ReactSortable
						<div className='project dragHandle' key={project._id}>
							<img
								src={project.cover?.secure_url}
								alt='project'
							></img>
							<h4>{project.name}</h4>
							<div className='btn-container'>
								<Link
									className='editButton'
									to={`/edit-project/${project._id}`}
									key={project._id}
								>
									<img
										className='editIcon'
										src={require('../../assets/icons/editar.png')}
										alt='edit-icon'
									/>
								</Link>
								<button
									className='deleteButton'
									onClick={(e) => deleteProject(project._id)}
								>
									X
								</button>
							</div>
						</div>
					))}
				</ReactSortable>
			</div>
		</div>
	);
};
