import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './CreateStudio.scss';
import { Error } from '../../components/Error/Error.jsx';
import { Loader } from '../../components/Loader/Loader.jsx';
import { API_URL } from '../../config.js';
import { TextEditor } from '../../components/TextEditor/TextEditor.jsx';

export const CreateStudio = () => {
	return (
		<div className='CreateStudio'>
			<div className='CreateStudio-form'>
				<h1>Create Studio</h1>
				<h4>Pendiente de hacer</h4>
			</div>
		</div>
	);
};

// export const CreateStudio = () => {
// 	//Crear loader:
// 	const [loading, setLoading] = useState(false);
// 	//Crear error:
// 	const [error, setError] = useState(null);
// 	//Mensaje de éxito:
// 	const [success, setSuccess] = useState(null);
// 	const [studio, setStudio] = useState('');
// 	const [data, setData] = useState([]);

// 	const getData = async () => {
// 		try {
// 			setLoading(true);
// 			setError(null);

// 			const url = `${API_URL}/studio`;
// 			const { data: response } = await axios.get(url);
// 			//console.log(response.data);
// 			if (response.data.length > 0) {
// 				setData(response.data);
// 				setStudio(response.data[0].studio);
// 				//console.log(response.data);
// 			}
// 			//setStudio('');
// 			setLoading(false);
// 		} catch (error) {
// 			console.log(error);
// 			setError(error.message);
// 		}
// 	};

// 	useEffect(() => {
// 		getData();
// 	}, []);

// 	const handleSubmit = async (event) => {
// 		event.preventDefault();

// 		try {
// 			setLoading(true);
// 			setSuccess(null);
// 			setError(null);

// 			//console.log(studio);
// 			if (data?.length === 0) {
// 				// console.log('POST!!!!');
// 				const { data: response } = await axios.post(
// 					`${API_URL}/studio`,
// 					{
// 						studio: studio,
// 					}
// 				);
// 				getData();
// 				//Mensaje de éxito:
// 				setSuccess('Studio creado con éxito');
// 			} else {
// 				//Si el studio ya tiene algo, la petición será un put:
// 				const { data: response } = await axios.put(
// 					`${API_URL}/studio/${data[0]._id}`,

// 					{
// 						studio: studio,
// 					}
// 				);
// 				getData();
// 				// console.log(response.data);
// 				//Mensaje de éxito:
// 				setSuccess('Studio modificado con éxito');
// 			}

// 			//TODO: Manejar las respuestas según el status code
// 		} catch (error) {
// 			console.log(error);
// 			setError(error.message);
// 		} finally {
// 			//Quitar el loader:
// 			setLoading(false);
// 		}
// 	};

// 	//Borrar el studio en base de datos:
// 	const deleteStudio = async () => {
// 		//mostrar mensaje de confirmación
// 		const confirm = window.confirm(
// 			'¿Estás seguro de que quieres eliminar?'
// 		);
// 		if (!confirm) return;
// 		try {
// 			setLoading(true);
// 			setSuccess(null);
// 			setError(null);

// 			await axios.delete(`${API_URL}/studio`);
// 			setStudio('');
// 			getData();
// 			setSuccess('Studio borrado con éxito');
// 		} catch (error) {
// 			console.log(error);
// 			setError(error.message);
// 		} finally {
// 			setLoading(false);
// 		}
// 	};

// 	return (
// 		<div className='CreateStudio'>
// 			<form className='CreateStudio-form' onSubmit={handleSubmit}>
// 				{/* Mensajes de error de validación: */}
// 				{error && <Error error={error} setError={setError} />}
// 				{loading && <Loader />}
// 				{success && <p style={{ color: 'green' }}>{success}</p>}

// 				<h3>CREAR ESTUDIO</h3>
// 				<label>Studio:</label>
// 				<TextEditor editorState={studio} setEditorState={setStudio} />

// 				<button type='submit'>Enviar</button>
// 				<button
// 					style={{ backgroundColor: 'orange' }}
// 					type='button'
// 					onClick={deleteStudio}
// 				>
// 					Borrar
// 				</button>
// 			</form>
// 		</div>
// 	);
// };
