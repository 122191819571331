export const Error = ({ error, setError }) => {
	const style = {
		padding: '10px 15px',
		borderRadius: '5px',
		margin: '10px 0',
		color: '#fff',
		display: 'flex',
		alignItems: 'center',
		fontSize: '16px',
		backgroundColor: '#f44336',
	};

	return (
		<div className='error' style={style}>
			{error}
		</div>
	);
};
