//Función para manejar el click en los details:

export const handleClickDetail = (e, { setSelectedCategory }) => {
	//Cuando hago clic por defecto o selecciona el elemento summary y el span.
	//Si detecta primero el summary, selecciono el parentNode (details), sino selecciono el parentNode del parentNode (details):
	let selectedDetail;

	if (e.target.tagName === 'SUMMARY') {
		selectedDetail = e.target.parentNode; //El onclik detecta primero el summary y luego el details, por eso tengo que ir al parentNode.
	} else if (e.target.tagName === 'SPAN') {
		selectedDetail = e.target.parentNode.parentNode;
	}

	//Una vez que ya sé el details seleccionado, cierro todos los details menos el seleccionado:
	if (e.target.tagName === 'SUMMARY' || e.target.tagName === 'SPAN') {
		//Cerrar todos los details menos el seleccionado:
		const details = document.querySelectorAll('details');
		details.forEach((detail) => {
			if (detail !== selectedDetail) {
				detail.open = false;
			}
		});

		//Si al pulsar el details está abierto, capturo su categoría y la guardo en el estado, sino reseteo el estado:
		if (!selectedDetail.open) {
			setSelectedCategory(selectedDetail.getAttribute('category'));
		} else {
			setSelectedCategory('');
		}
	}

	/*< Función para mover la barra de los summary >*/

	// Cuando el onClick detecte el elemento Summary
	if (e.target.tagName === 'SUMMARY') {
		if (e.target.parentNode.open) {
			e.target.children[0].innerText = '/';
		} else {
			e.target.children[0].innerText = '__';
			// Poner la barra de todos los elementos summary excepto el seleccionado:
			const summaries = document.querySelectorAll('summary');
			summaries.forEach((summary) => {
				if (summary !== e.target) {
					summary.children[0].innerText = '/';
				}
			});
		}
	}
	// Cuando el onClick detecte el elemento Span
	if (e.target.tagName === 'SPAN') {
		if (e.target.innerText === '__') {
			e.target.innerText = '/';
			e.target.parentNode.parentNode.open = true;
		} else {
			e.target.innerText = '__';
			e.target.parentNode.parentNode.open = false;
			// Poner la barra de todos los elementos summary excepto el seleccionado:
			const spans = document.querySelectorAll('span');
			spans.forEach((span) => {
				if (span !== e.target) {
					span.innerText = '/';
				}
			});
		}
	}
};

/*----------------------- Pantalla completa de las imágenes -----------------------

	//Función para poner a pantalla completa una imagen:
	const handleClickImage = (e) => {
		const image = e.target;
		if (image.requestFullscreen) {
			image.requestFullscreen();
		} else if (image.webkitRequestFullscreen) {
			image.webkitRequestFullscreen();
		} else if (image.msRequestFullscreen) {
			image.msRequestFullscreen();
		}
	};

	//Detectar el cambio de imagen y aplicar la función:
	useEffect(() => {
		const images = document.querySelectorAll('img');
		images.forEach((image) => {
			image.addEventListener('click', handleClickImage);
		});
	}, [images]);
	*/
