import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './Carousel.scss';

export const Carousel = ({ images, currentImageIndex, closeCarousel }) => {
	const [currentIndex, setCurrentIndex] = useState(currentImageIndex);

	// Manejador de teclas para cerrar con "Escape"
	useEffect(() => {
		const handleKeyDown = (e) => {
			if (e.key === 'Escape') {
				closeCarousel();
			} else if (e.key === 'ArrowLeft') {
				goToPrevious();
			} else if (e.key === 'ArrowRight') {
				goToNext();
			}
		};
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [closeCarousel]);

	// Funciones para navegar entre imágenes
	const goToPrevious = () => {
		setCurrentIndex(
			(prevIndex) => (prevIndex - 1 + images.length) % images.length
		);
	};

	const goToNext = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
	};

	// Manejador para cerrar el carrusel al hacer clic fuera de la imagen
	const handleOverlayClick = (e) => {
		if (e.target.classList.contains('carousel-overlay')) {
			closeCarousel();
		}
	};

	return ReactDOM.createPortal(
		<div className='carousel-overlay' onClick={handleOverlayClick}>
			<div className='carousel'>
				<button className='close-button' onClick={closeCarousel}>
					x
				</button>
				<button
					className='nav-button prev-button'
					onClick={goToPrevious}
				>
					&lt;-
				</button>
				<img
					src={images[currentIndex]}
					alt={`Imagen ${currentIndex + 1}`}
					className='carousel-image'
				/>
				<button className='nav-button next-button' onClick={goToNext}>
					-&gt;
				</button>
			</div>
		</div>,
		document.body
	);
};
