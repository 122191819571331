/*< Función para mover la barra de los summary >*/
export const moveBarOfSummary = (e) => {
	// console.log(e.target);
	// Cuando el onClick detecte el elemento Summary
	if (e.target.tagName === 'SUMMARY') {
		if (e.target.parentNode.open) {
			e.target.children[0].innerText = '/';
		} else {
			e.target.children[0].innerText = '__';
		}
	}
	// Cuando el onClick detecte el elemento Span
	if (e.target.tagName === 'SPAN') {
		if (e.target.parentNode.parentNode.open) {
			e.target.innerText = '/';
		} else {
			e.target.innerText = '__';
		}
	}
};
