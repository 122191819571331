import './BlancoDeCazador.scss';
import { Link } from 'react-router-dom';

export const BlancoDeCazador = () => {
	/*< Función para mover la barra de los summary >*/
	const moveBarOfSummary = (e) => {
		// console.log(e.target);
		// Cuando el onClick detecte el elemento Summary
		if (e.target.tagName === 'SUMMARY') {
			if (e.target.parentNode.open) {
				e.target.children[0].innerText = '/';
			} else {
				e.target.children[0].innerText = '__';
			}
		}
		// Cuando el onClick detecte el elemento Span
		if (e.target.tagName === 'SPAN') {
			if (e.target.parentNode.parentNode.open) {
				e.target.innerText = '/';
			} else {
				e.target.innerText = '__';
			}
		}
	};

	return (
		<div className='BlancoDeCazador'>
			<div className='bdc-img-container'>
				<img
					src={require(`../../assets/img/bdc_1.jpg`)}
					alt='fotografía de Iván Blanco'
				/>
				<img
					src={require(`../../assets/img/bdc_2.jpg`)}
					alt='fotografía de Iván Blanco'
				/>
				<img
					src={require(`../../assets/img/bdc_3.jpg`)}
					alt='fotografía de Iván Blanco'
				/>
			</div>
			<div className='bdc-text-container'>
				<div className='bdc-text-inner-container'>
					<p>
						Blanco Decazador, solo project by the artist based in
						Galicia and born in Canary Islands, Iván Blanco (1982).{' '}
						<span>
							<Link to='/about'>&lt;--</Link>
						</span>
					</p>

					<div>
						<details onClick={(e) => moveBarOfSummary(e)}>
							<summary>
								LPs<span>/</span>
							</summary>
							<div>
								<p>
									2023 <b>Reworks</b> Blanco Decazador/{' '}
									<b>LP</b>
								</p>
							</div>
						</details>
						<details onClick={(e) => moveBarOfSummary(e)}>
							<summary>
								Singles<span>/</span>
							</summary>
							<div>
								<p>
									2023 <b>Rewind</b> Blanco Decazador by
									Sonido Atmosférico/{' '}
									<b>track in Compilation album</b>
								</p>
							</div>
						</details>
						<details onClick={(e) => moveBarOfSummary(e)}>
							<summary>
								Tracks on movies<span>/</span>
							</summary>
							<div>
								<p>
									<b>Micrópolis</b> directed by Vic Pereiró y
									Sara Esteller/ <b>Track: Rw#1 (Reworks)</b>
								</p>
							</div>
						</details>
					</div>
				</div>
			</div>
		</div>
	);
};
