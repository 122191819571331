import React from 'react';
import './Contact.scss';

export const Contact = () => {
	return (
		<div className='Contact'>
			<div className='contact-text-container'>
				<p>
					<a href='mailto:sr.blanco.00@gmail.com'>
						sr.blanco.00@gmail.com
					</a>
				</p>
				<br />
				<p>
					<a href='tel:+34606674688'>+34 606 67 46 88</a>
				</p>
			</div>
			<div className='contact-img-container'>
				<img
					src={require(`../../assets/img/contact.jpg`)}
					alt='fotografía de Iván Blanco'
				/>
			</div>
		</div>
	);
};
