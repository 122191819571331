import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Project.scss';
import axios from 'axios';
import { Error } from '../../components/Error/Error.jsx';
import { Loader } from '../../components/Loader/Loader.jsx';
import { API_URL } from '../../config';
import { Carousel } from '../../components/Carousel/Carousel.jsx';

export const Project = () => {
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [project, setProject] = useState(null);

	// Estado para el carrusel
	const [isCarouselOpen, setIsCarouselOpen] = useState(false);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const getProject = async () => {
		setLoading(true);
		setError(null);
		const url = `${API_URL}/projects/${id}`;

		try {
			const { data: response } = await axios.get(url);
			setProject(response.data);
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getProject();
	}, [setProject]); // eslint-disable-line

	// Función para abrir el carrusel y establecer la imagen seleccionada
	const openCarousel = (index) => {
		setCurrentImageIndex(index);
		setIsCarouselOpen(true);
	};

	const closeCarousel = () => {
		setIsCarouselOpen(false);
	};

	return (
		<div className='Project'>
			{loading && <Loader />}
			{error && <Error message={error} />}
			{project && (
				<>
					<div className='project-media-container'>
						{project.video && (
							<video
								src={project.video.secure_url}
								controls
							></video>
						)}
						{project.image && (
							<div className='project-img-container'>
								{/* Se agregan las imágenes a la galería */}
								<img
									src={project.image.secure_url}
									alt={project.name}
									onClick={() => openCarousel(0)} // Abre el carrusel con la primera imagen
									className='gallery-image'
								/>
								{project.image_2 && (
									<img
										src={project.image_2.secure_url}
										alt={project.name}
										onClick={() => openCarousel(1)} // Abre el carrusel con la segunda imagen
										className='gallery-image'
									/>
								)}
							</div>
						)}
						{project.external_video && (
							<div
								className='external-video'
								dangerouslySetInnerHTML={{
									__html: project.external_video,
								}}
							></div>
						)}

						<div className='audio-link-container'>
							{project.audio && (
								<audio controls>
									<source
										src={project.audio.secure_url}
										type='audio/mpeg'
									/>
									Your browser does not support the audio
									element.
								</audio>
							)}
							{project.external_link && (
								<a
									href={project.external_link}
									target='_blank'
									rel='noreferrer'
								>
									{project.external_link_name}
								</a>
							)}
							{project.bandcamp && (
								<div
									dangerouslySetInnerHTML={{
										__html: project.bandcamp,
									}}
								></div>
							)}
						</div>
					</div>
					<div className='project-text-container'>
						<div className='project-text-inner-container'>
							<div
								dangerouslySetInnerHTML={{
									__html: project.description,
								}}
							></div>
						</div>
					</div>

					{/* Renderiza el carrusel si está abierto */}
					{isCarouselOpen && (
						<Carousel
							images={[
								project.image.secure_url,
								project.image_2?.secure_url,
							]}
							currentImageIndex={currentImageIndex}
							closeCarousel={closeCarousel}
						/>
					)}
				</>
			)}
		</div>
	);
};
