import React, { useState, useEffect } from 'react';
import './AboutCategoriesList.scss';
import { Error } from '../../components/Error/Error.jsx';
import { Loader } from '../../components/Loader/Loader.jsx';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { API_URL } from '../../config.js';
import { BackButton } from '../../components/BackButton/BackButton.jsx';

export const AboutCategoriesList = () => {
	const [categoriesList, setCategoriesList] = useState([]);
	const [sortedCategoriesList, setSortedCategoriesList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [message, setMessage] = useState(null);

	/* < Obtener la lista de categorías > */

	const getCategories = async () => {
		setLoading(true);
		const url = `${API_URL}/about`;

		try {
			const { data: response } = await Axios.get(url);
			setCategoriesList(response.data);
			//console.log(response.data);
		} catch (error) {
			console.log(error);
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getCategories();
	}, [setCategoriesList]); // eslint-disable-line

	/* < Ordenar las categorías por fecha de creación > */
	const sortCategories = () => {
		const sorted = categoriesList?.sort((a, b) => {
			return new Date(b.createdAt) - new Date(a.createdAt);
		});
		setSortedCategoriesList(sorted);
	};

	useEffect(() => {
		sortCategories();
	}, [categoriesList]); // eslint-disable-line

	/* < Eliminar categoría > */
	const deleteCategory = async (id) => {
		const url = `${API_URL}/about/${id}`;
		//mostrar mensaje de confirmación
		const confirm = window.confirm(
			'¿Estás seguro de que quieres eliminar la categoría (About)?'
		);
		if (!confirm) return;
		try {
			const { data: response } = await Axios.delete(url);
			//console.log(response);
			setMessage(response.message);
			getCategories();
		} catch (error) {
			console.log(error);
			setError(error.message);
		}
	};

	return (
		<div className='AboutCategoriesList'>
			{loading && <Loader />}

			<div className='container'>
				<BackButton to='/dashboard' text='Dashboard' />
				<h3>LISTA DE CATEGORÍAS (ABOUT)</h3>

				{message && <p style={{ color: 'green' }}>{message}</p>}
				{error && <Error message={error} />}

				{sortedCategoriesList?.length === 0 && (
					<h4>No hay categorías todavía</h4>
				)}
				{sortedCategoriesList?.map((aboutCategory) => (
					<div className='aboutCategory' key={aboutCategory._id}>
						<h4>{aboutCategory.title}</h4>
						<div className='btn-container'>
							<Link
								className='editButton'
								to={`/edit-aboutCategory/${aboutCategory._id}`}
								key={aboutCategory._id}
							>
								<img
									className='editIcon'
									src={require('../../assets/icons/editar.png')}
									alt='edit-icon'
								/>
							</Link>
							<button
								className='deleteButton'
								onClick={(e) =>
									deleteCategory(aboutCategory._id)
								}
							>
								X
							</button>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
