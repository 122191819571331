import React from 'react';
import './Logout.scss';

export const Logout = () => {
	const logout = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('userId');
		window.location.replace('/');
	};

	return (
		<div className='Logout'>
			<button onClick={logout}>Logout</button>
		</div>
	);
};
