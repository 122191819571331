import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import './Layout.scss';
import { Glitch } from '../components/Glitch/Glitch';
// import { Grid } from '../components/Grid/Grid';

export const Layout = () => {
	// Esto lo voy a usar para obtener en que página me encuentro para ocultar el logo de navegación en el home
	const location = useLocation();

	return (
		<div className='Layout'>
			{/* <Grid numRow='8' numCol='12' /> */}
			<Link
				id='logo'
				to='/'
				// Si estoy en el home no se muestra el enlace:
				style={
					location.pathname === '/'
						? { display: 'none' }
						: { display: 'block' }
				}
			>
				<Glitch text='IB' sizeFont='1.5rem' />
			</Link>
			<Outlet />
		</div>
	);
};
