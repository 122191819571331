import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import './EditProject.scss';
import { Error } from '../../components/Error/Error.jsx';
import { Loader } from '../../components/Loader/Loader.jsx';
import { Logout } from '../../components/Logout/Logout';
import { API_URL } from '../../config';
import { TextEditor } from '../../components/TextEditor/TextEditor';

export const EditProject = () => {
	//OBTENER LOS DATOS DEL PROYECTO A EDITAR PARA MOSTRARLOS EN EL FORMULARIO:
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [formData, setFormData] = useState({
		name: '',
		title: '',
		description: '',
		cover: null,
		image: null,
		image_2: null,
		video: null,
		audio: null,
		external_video: '',
		external_link: '',
		external_link_name: '',
		bandcamp: '',
	});
	const [description, setDescription] = useState('');

	const getProject = async () => {
		try {
			setLoading(true);
			setError(null);

			const url = `${API_URL}/projects/${id}`;
			const { data: response } = await axios.get(url);
			//console.log(response.data);
			setFormData({
				name: response.data.name,
				title: response.data.title,
				description: response.data.description,
				cover: response.data.cover,
				image: response.data.image,
				image_2: response.data.image_2,
				video: response.data.video,
				audio: response.data.audio,
				external_video: response.data.external_video,
				external_link: response.data.external_link,
				external_link_name: response.data.external_link_name,
				bandcamp: response.data.bandcamp,
			});
			setDescription(response.data.description);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setError(error.message);
		}
	};

	useEffect(() => {
		getProject();
	}, []); // eslint-disable-line

	//FORMULARIO PARA LA EDICIÓN DEL PROYECTO

	const handleChange = (event) => {
		const { name, value, files } = event.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: files ? files[0] : value,
		}));
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		try {
			setLoading(true);
			setSuccess(null);
			setError(null);

			//Limitar el tamaño de imágenes a 5MB:
			if (formData.cover && formData.cover.size > 5000000) {
				setError('El tamaño máximo de la imagen de portada es de 5MB');
				return;
			}
			if (formData.image && formData.image.size > 5000000) {
				setError('El tamaño máximo de la imagen 1 es de 5MB');
				return;
			}
			if (formData.image_2 && formData.image_2.size > 5000000) {
				setError('El tamaño máximo de la imagen 2 es de 5MB');
				return;
			}
			//Validar el tamaño máximos de los vídeos y audios a 50MB:
			if (formData.video && formData.video.size > 50000000) {
				setError('El tamaño máximo del vídeo es de 50MB');
				return;
			}
			if (formData.audio && formData.audio.size > 50000000) {
				setError('El tamaño máximo del audio es de 50MB');
				return;
			}

			//TODO: A partir de aquí es todo igual que en CreateProject.jsx, excepto un par de cosas.
			const formDataToSend = new FormData();
			if (formData.name) formDataToSend.append('name', formData.name);
			if (formData.title) formDataToSend.append('title', formData.title);
			if (description) formDataToSend.append('description', description);
			if (formData.cover) formDataToSend.append('cover', formData.cover);
			if (formData.image) formDataToSend.append('image', formData.image);
			if (formData.image_2)
				formDataToSend.append('image_2', formData.image_2);
			if (formData.video) formDataToSend.append('video', formData.video);
			if (formData.audio) formDataToSend.append('audio', formData.audio);
			if (formData.external_video)
				formDataToSend.append(
					'external_video',
					formData.external_video
				);
			if (formData.external_link)
				formDataToSend.append('external_link', formData.external_link);
			if (formData.external_link_name)
				formDataToSend.append(
					'external_link_name',
					formData.external_link_name
				);
			formDataToSend.append('bandcamp', formData.bandcamp);

			//Hacer la petición con axios:
			const { data: response } = await axios.put(
				`${API_URL}/projects/${id}`,
				formDataToSend,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);

			//console.log(response.data);
			//Mensaje de éxito:
			setSuccess('Proyecto actualizado con éxito');

			//TODO: Manejar las respuestas según el status code
		} catch (error) {
			console.log(error);
			setError(error.message);
		} finally {
			//Quitar el loader:
			setLoading(false);
		}
	};

	return (
		<div className='EditProject'>
			<Logout />
			<form className='EditProject-form' onSubmit={handleSubmit}>
				{/* Mensajes de error de validación: */}
				{error && <Error error={error} setError={setError} />}
				{loading && <Loader />}
				{success && <p style={{ color: 'green' }}>{success}</p>}

				<h3>EDITAR PROYECTO</h3>
				<label>Nombre:</label>
				<input
					type='text'
					name='name'
					onChange={handleChange}
					value={formData.name}
				/>

				<label>Título:</label>
				<input
					type='text'
					name='title'
					onChange={handleChange}
					value={formData.title}
				/>

				<label>Descripción:</label>
				<TextEditor
					editorState={description}
					setEditorState={setDescription}
				/>

				<label>Imagen de portada:</label>
				<div className='input-container'>
					<input
						type='file'
						name='cover'
						onChange={handleChange}
						accept='image/*'
					/>
					{formData.cover?.secure_url && (
						<img
							src={formData.cover?.secure_url}
							alt='imagen de portada del proyecto'
						/>
					)}
				</div>

				<label>Imagen 1:</label>
				<div className='input-container'>
					<input
						type='file'
						name='image'
						onChange={handleChange}
						accept='image/*'
					/>
					{formData.image?.secure_url && (
						<img
							src={formData.image?.secure_url}
							alt='imagen 1 del proyecto'
						/>
					)}
				</div>

				<label>Imagen 2:</label>
				<div className='input-container'>
					<input
						type='file'
						name='image_2'
						onChange={handleChange}
						accept='image/*'
					/>
					{formData.image?.secure_url && (
						<img
							src={formData.image_2?.secure_url}
							alt='imagen 2 del proyecto'
						/>
					)}
				</div>

				<label>Video:</label>
				<div className='input-container'>
					<input
						type='file'
						name='video'
						onChange={handleChange}
						accept='video/*'
					/>
					{formData.video?.secure_url && (
						<video
							src={formData.video?.secure_url}
							controls
						></video>
					)}
				</div>

				<label>Audio:</label>
				<div className='input-container'>
					<input
						type='file'
						name='audio'
						onChange={handleChange}
						accept='audio/*'
					/>
					{formData.audio?.secure_url && (
						<audio
							src={formData.audio?.secure_url}
							controls
						></audio>
					)}
				</div>

				<label>Enlace a vídeo externo:</label>
				<input
					type='text'
					name='external_video'
					onChange={handleChange}
					value={formData.external_video}
				/>

				<label>Enlace externo:</label>
				<input
					type='text'
					name='external_link'
					onChange={handleChange}
					value={formData.external_link}
				/>

				<label>Nombre enlace externo:</label>
				<input
					type='text'
					name='external_link_name'
					onChange={handleChange}
					value={formData.external_link_name}
				/>

				<label>Bandcamp:</label>
				<input
					type='text'
					name='bandcamp'
					onChange={handleChange}
					value={formData.bandcamp}
				/>

				<button type='submit'>Enviar</button>
			</form>
		</div>
	);
};
