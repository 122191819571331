import React, { useState } from 'react';
import './Studio.scss';
import { material } from './material';
import { handleClickDetail } from './auxFunctions.js';

export const Studio = () => {
	//----------------------- Comportamiento de apertura y cierre de los details -----------------------
	//Categoría seleccionada:
	const [selectedCategory, setSelectedCategory] = useState(''); //Por defecto salen las de la primera categoría

	// Seleccionar las imágenes de material que coincidan con la categoría seleccionada:
	const images = selectedCategory
		? material.filter((item) => item.category === selectedCategory)[0]
				.images
		: [];

	return (
		<div className='Studio'>
			{/* Si selectedCategory no está vacía renderiza las imágenes de esa categoría sino las imágenes por defecto: */}
			{selectedCategory ? (
				<div className='studio-img-container'>
					{images.map((item, index) => {
						return (
							<img
								key={index}
								src={require(`../../assets/img/${item.secure_url}`)}
								alt='Studio images'
							/>
						);
					})}
				</div>
			) : (
				<div className='studio-img-container'>
					<img
						src={require(`../../assets/img/studio_1.jpg`)}
						alt='fotografía de Iván Blanco'
					/>
					<img
						src={require(`../../assets/img/studio_2.jpg`)}
						alt='fotografía de Iván Blanco'
					/>
					<img
						src={require(`../../assets/img/studio_3.jpg`)}
						alt='fotografía de Iván Blanco'
					/>
				</div>
			)}
			<div className='studio-text-container'>
				<div className='studio-text-inner-container'>
					{material.map((item, index) => {
						return (
							<div key={index}>
								<details
									onClick={(e) =>
										handleClickDetail(e, {
											setSelectedCategory,
										})
									}
									category={item.category}
								>
									<summary>
										{item.title}
										<span>/</span>
									</summary>
									<div>{item.material}</div>
								</details>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};
