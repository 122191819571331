import './App.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from './Layout/Layout.jsx';
import { Home } from './pages/Home/Home.jsx';
import { About } from './pages/About/About.jsx';
import { BlancoDeCazador } from './pages/BlancoDeCazador/BlancoDeCazador.jsx';
import { Projects } from './pages/Projects/Projects.jsx';
import { Contact } from './pages/Contact/Contact.jsx';
import { NotFound } from './pages/NotFound/NotFound';
import { Project } from './pages/Project/Project';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Studio } from './pages/Studio/Studio';
import { EditProject } from './pages/EditProject/EditProject';
import Login from './pages/Login/Login';
import { EditAboutCategory } from './pages/EditAboutCategory/EditAboutCategory';

import { CreateProject } from './pages/CreateProject/CreateProject';
import { ProjectList } from './pages/ProjectList/ProjectList';
import { CreateAbout } from './pages/CreateAbout/CreateAbout.jsx';
import { AboutCategoriesList } from './pages/AboutCategoriesList/AboutCategoriesList.jsx';
import { CreateStudio } from './pages/CreateStudio/CreateStudio.jsx';

function App() {
	const token = localStorage.getItem('token');

	return (
		<div className='App'>
			<BrowserRouter>
				<Routes>
					<Route path='/' element={<Layout />}>
						{/* Rutas privadas */}
						{token && (
							<>
								<Route
									path='/create-project'
									element={<CreateProject />}
								/>
								<Route
									path='/list-projects'
									element={<ProjectList />}
								/>
								<Route
									path='/edit-project/:id'
									element={<EditProject />}
								/>
								<Route
									path='/create-category-about'
									element={<CreateAbout />}
								/>
								<Route
									path='/list-categories-about'
									element={<AboutCategoriesList />}
								/>
								<Route
									path='/edit-aboutCategory/:id'
									element={<EditAboutCategory />}
								/>
								<Route
									path='/create-studio'
									element={<CreateStudio />}
								/>
								<Route
									path='/dashboard'
									element={<Dashboard />}
								></Route>
								<Route
									path='/login'
									element={
										<Navigate replace to='/dashboard' />
									}
								/>
							</>
						)}
						{/* Rutas públicas */}
						<Route index element={<Home />} />
						<Route path='/about' element={<About />}></Route>
						<Route
							path='/blancodecazador'
							element={<BlancoDeCazador />}
						></Route>
						<Route path='/projects' element={<Projects />}></Route>
						<Route path='/contact' element={<Contact />}></Route>
						<Route path='/studio' element={<Studio />}></Route>
						<Route path='/:id' element={<Project />} />
						<Route path='/login' element={<Login />} />
						<Route
							path='/dashboard'
							element={<Navigate replace to='/login' />}
						/>
						<Route
							path='/edit-project/:id'
							element={<Navigate replace to='/login' />}
						/>
						<Route
							path='/edit-aboutCategory/:id'
							element={<Navigate replace to='/login' />}
						/>
						<Route path='*' element={<NotFound />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
