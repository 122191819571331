import React from 'react';
import './Glitch.scss';

export const Glitch = ({ text, sizeFont }) => {
	const styles = {
		fontSize: sizeFont,
	};
	return (
		<div className='glitch'>
			<div style={styles} className='line'>
				{text}
			</div>
			<div style={styles} className='line'>
				{text}
			</div>
			<div style={styles} className='line'>
				{text}
			</div>
			<div style={styles} className='line'>
				{text}
			</div>
			<div style={styles} className='line'>
				{text}
			</div>
			<div style={styles} className='line'>
				{text}
			</div>
			<div style={styles} className='line'>
				{text}
			</div>
			<div style={styles} className='line'>
				{text}
			</div>
			<div style={styles} className='line'>
				{text}
			</div>
		</div>
	);
};
