import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './TextEditor.scss';

export const TextEditor = ({ editorState, setEditorState }) => {
	// Configuración para el editor de texto:
	const modules = {
		toolbar: [
			[{ header: [1, 2, 3, 4, 5, 6, false] }],
			['bold', 'italic', 'underline', 'strike'],
			['blockquote', 'code-block'],
			[{ list: 'ordered' }, { list: 'bullet' }],
			[{ indent: '-1' }, { indent: '+1' }],
			[{ align: [] }],
			[{ color: [] }, { background: [] }],
			[{ font: [] }],
			[{ size: ['small', false, 'large', 'huge'] }],
			['link', 'image'],
			['clean'],
		],
		clipboard: {
			// toggle to add extra line breaks when pasting HTML:
			matchVisual: false,
		},
	};

	const handleChange = (value) => {
		setEditorState(value);
	};

	return (
		<ReactQuill
			className='ReactQuill'
			value={editorState}
			onChange={handleChange}
			placeholder={'Escribe una descripción...'}
			modules={modules}
			setState={setEditorState}
		/>
	);
};
