import React from 'react';

export const Message = ({ message, color }) => {
	// Definimos los estilos predeterminados para el componente
	const baseStyle = {
		padding: '10px 15px',
		borderRadius: '5px',
		margin: '10px 0',
		color: '#fff',
		display: 'flex',
		alignItems: 'center',
		fontSize: '16px',
	};

	// Definimos un objeto de colores según el tipo de mensaje
	const colors = {
		success: '#4caf50', // Verde para éxito
		error: '#f44336', // Rojo para error
		warning: '#ff9800', // Naranja para advertencia
	};

	// Determinamos el color final a usar, si no se encuentra en `colors`, se usa directamente
	const finalColor = colors[color] || color || '#2196f3'; // Azul predeterminado si no hay color

	// Estilo final combinado
	const alertStyle = {
		...baseStyle,
		backgroundColor: finalColor,
	};

	return <div style={alertStyle}>{message}</div>;
};
