import { useState } from 'react';
import axios from 'axios';
import './Login.scss';

import { API_URL } from '../../config';

const Login = () => {
	const [data, setData] = useState({ name: '', password: '' });
	const [error, setError] = useState('');

	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const url = `${API_URL}/auth`;
			const { data: res } = await axios.post(url, data);
			//console.log(res);
			localStorage.setItem('token', res.data.token);
			localStorage.setItem('userId', res.data.user._id);
			window.location = '/dashboard';
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
			}
		}
	};

	return (
		<div className='Login'>
			<form className='form_container' onSubmit={handleSubmit}>
				<h2>Ingresa a tu Cuenta</h2>
				<input
					type='name'
					placeholder='name'
					name='name'
					onChange={handleChange}
					value={data.name}
					required
					className='input'
				/>
				<input
					type='password'
					placeholder='Contraseña'
					name='password'
					onChange={handleChange}
					value={data.password}
					required
					className='input'
				/>
				{error && <div className='error_msg'>{error}</div>}
				<button type='submit' className='login_btn'>
					Ingresa
				</button>
			</form>
		</div>
	);
};

export default Login;
