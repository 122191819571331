import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import './EditAboutCategory.scss';
import { Error } from '../../components/Error/Error.jsx';
import { Loader } from '../../components/Loader/Loader.jsx';
import { Logout } from '../../components/Logout/Logout';
import { API_URL } from '../../config';
import { TextEditor } from '../../components/TextEditor/TextEditor';

export const EditAboutCategory = () => {
	//OBTENER LOS DATOS DEL LA CATEGORÍA (ABOUT) A EDITAR PARA MOSTRARLOS EN EL FORMULARIO:
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [data, setData] = useState({
		category: '',
		title: '',
		description: '',
	});
	const [description, setDescription] = useState('');

	const getCategory = async () => {
		try {
			setLoading(true);
			setError(null);

			const url = `${API_URL}/about/${id}`;
			const { data: response } = await axios.get(url);
			console.log(response.data);
			setData({
				category: response.data.category,
				title: response.data.title,
				description: response.data.description,
			});
			setDescription(response.data.description);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setError(error.message);
		}
	};

	useEffect(() => {
		getCategory();
	}, []); // eslint-disable-line

	//FORMULARIO PARA EDITAR UNA CATEGORÍA (ABOUT):

	const handleChange = (event) => {
		const { name, value, files } = event.target;
		setData((prevState) => ({
			...prevState,
			[name]: files ? files[0] : value,
		}));
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		try {
			setLoading(true);
			setSuccess(null);
			setError(null);

			//TODO: Validaciones?

			//TODO: A partir de aquí es todo igual que en CreateAbout.jsx, excepto un par de cosas.
			//Hacer la petición con axios:
			const { data: response } = await axios.put(
				`${API_URL}/about/${id}`,
				{
					category: data.category,
					title: data.title,
					description: description,
				}
			);

			console.log(response.data);
			//Mensaje de éxito:
			setSuccess('Categoría modificada con éxito');

			//TODO: Manejar las respuestas según el status code
		} catch (error) {
			console.log(error);
			setError(error.message);
		} finally {
			//Quitar el loader:
			setLoading(false);
		}
	};

	return (
		<div className='EditProject'>
			<Logout />
			<form className='EditProject-form' onSubmit={handleSubmit}>
				{/* Mensajes de error de validación: */}
				{error && <Error error={error} setError={setError} />}
				{loading && <Loader />}
				{success && <p style={{ color: 'green' }}>{success}</p>}

				<h3>EDITAR CATEGORÍA (ABOUT)</h3>
				<label>Categoría:</label>
				<input
					type='text'
					name='category'
					onChange={handleChange}
					value={data.category}
				/>

				<label>Título:</label>
				<input
					type='text'
					name='title'
					onChange={handleChange}
					value={data.title}
				/>

				<label>Descripción:</label>
				<TextEditor
					editorState={description}
					setEditorState={setDescription}
				/>

				<button type='submit'>Enviar</button>
			</form>
		</div>
	);
};
