import React from 'react';
import './NotFound.scss';

export const NotFound = () => {
	return (
		<div className='NotFound'>
			<h1>NotFound</h1>
		</div>
	);
};
