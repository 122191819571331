import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import './About.scss';
import { Error } from '../../components/Error/Error.jsx';
import { Loader } from '../../components/Loader/Loader.jsx';
import { API_URL } from '../../config';
import { moveBarOfSummary } from './auxFunctions.js';

export const About = () => {
	const [loading, setLoading] = useState(false);
	//Nota: En este componente no me interesa mostrar mensaje de éxito
	const [error, setError] = useState(null);
	const [about, setAbout] = useState([]);

	const getData = async () => {
		try {
			setLoading(true);
			setError(null);

			const url = `${API_URL}/about`;
			const { data: response } = await axios.get(url);

			if (response.data.length > 0) {
				setAbout(response.data);
			}

			setLoading(false);
		} catch (error) {
			console.log(error);
			setError(error.message);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div className='About'>
			<div className='about-img-container'>
				<img
					src={require(`../../assets/img/about_1.jpg`)}
					alt='fotografía de Iván Blanco'
				/>
				<img
					src={require(`../../assets/img/about_2.jpg`)}
					alt='fotografía de Iván Blanco'
				/>
				<img
					src={require(`../../assets/img/about_3.jpg`)}
					alt='fotografía de Iván Blanco'
				/>
			</div>
			<div className='about-text-container'>
				<div className='about-text-inner-container'>
					<p>
						Iván Blanco (1982) composer and sound artist born in
						Canary Islands and based in Galicia.{' '}
						<span>
							<Link to='/blancodecazador'>--&gt;</Link>
						</span>
					</p>

					{error && <Error error={error} setError={setError} />}
					{loading && <Loader />}
					{about &&
						about.map((item, index) => {
							return (
								<div key={index}>
									<details
										onClick={(e) => moveBarOfSummary(e)}
									>
										<summary>
											{item.title}
											<span>/</span>
										</summary>
										<div
											dangerouslySetInnerHTML={{
												__html: item.description,
											}}
										></div>
									</details>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
};
