import React from 'react';
import { Link } from 'react-router-dom';
import { TbArrowBackUp } from 'react-icons/tb';

import './BackButton.scss';

export const BackButton = ({ to, text }) => {
	return (
		<Link to={to} className='BackButton'>
			<TbArrowBackUp /> {text}
		</Link>
	);
};
