import React from 'react';
import { Link } from 'react-router-dom';
import './Home.scss';
import home_gif from '../../assets/img/home_gif.gif';
import home_gif_mobile from '../../assets/img/home_gif(mobile).gif';

export const Home = () => {
	return (
		<div className='Home'>
			<div className='home-content'>
				<div className='home-texts'>
					<Link to='/'>
						<h2 id='home-title'>
							_COMPOSER <br />
							SOUND ENGINEER
						</h2>
					</Link>
					<nav>
						<div>
							<Link to='/about'>Iván Blanco</Link>
						</div>
						<div>
							<Link to='/blancodecazador'>Blanco Decazador</Link>
						</div>
						<div>
							<Link to='/projects'>projects</Link>
						</div>
						<div>
							<Link to='/studio'>studio</Link>
						</div>
						<div>
							<a
								href='https://blancodecazador.bandcamp.com/'
								target='_blank'
								rel='noreferrer'
							>
								bandcamp
							</a>
						</div>
						<div>
							<a
								href='https://www.instagram.com/blanco_decazador'
								target='_blank'
								rel='noreferrer'
							>
								instagram
							</a>
						</div>
						<div>
							<a href='mailto:sr.blanco.00@gmail.com'>contact</a>
						</div>
					</nav>
				</div>

				<picture id='home-img-container'>
					<source
						srcSet={home_gif_mobile}
						media='(max-width: 768px)'
					/>
					<source srcSet={home_gif} media='(min-width: 769px)' />
					<img src={home_gif} alt='tape machine' />
				</picture>
			</div>
		</div>
	);
};
